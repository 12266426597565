export const environment = {
  production: false,
  language: 'en',
  useMocks: false,
  useTranslations: true,
  api: {
    shopURL: 'https://shop-dev.devteam.win',
    phpURL: 'https://php-integration.dev.ordersmart.app',
    gatewayURL: 'https://api.dev.ordersmart.app',
    transactionsURL: 'https://loki-transactions.dev.ordersmart.app'
  },
  keycloak: {
    url: 'https://auth.devenv.ordersmart.app',
    clientId: 'loki',
    realm: 'test'
  },
  email: {
    sender: 'service@ordersmart.de',
    productionTeam: 'qa@app-smart.de'
  },
  zendesk: {
    widgetKey: '96b1163a-41e0-4afb-a167-7753ea08b1fa'
  },
  analytics: {
    google: {
      measurementId: 'G-3QFQDWW19Y',
      scriptSrc:'https://www.googletagmanager.com/gtag/js?id='
    }
  }
};
